<!--  -->
<template>
  <div class="zhuchangText">
    <img class="bg" src="../../images/rob/bg2.png" alt="" srcset="" />
    <div class="head_NavBar">
      <div class="title">主展场惠民券指南</div>
    </div>
    <div class="text_cont">
      <div class="text_box">
        <div class="jz sp">Step1</div>
        <img class="title" src="../../images/rob/s1.png" alt="" srcset="" />
        <div class="txt">11月18日-11月27日（每日10点开始）</div>
      </div>
      <div class="text_box">
        <div class="jz sp">Step2</div>
        <img class="title" src="../../images/rob/s2.png" alt="" srcset="" />
        <div class="txt">每日88张券，先到先得</div>
      </div>
      <div class="text_box">
        <div class="jz sp">Step3</div>
        <img class="title" src="../../images/rob/s3.png" alt="" srcset="" />
        <div class="txt">11月18日-11月27日每日18点之前短信通知</div>
        <img
          class="fangshi"
          src="../../images/rob/fangshi.png"
          alt=""
          srcset=""
        />
      </div>
      <div class="text_box">
        <div class="jz sp">Step4</div>
        <img class="title" src="../../images/rob/s4.png" alt="" srcset="" />
        <div class="txt">11月25日-11月28日中午12:00截止</div>
      </div>
      <div class="text_box">
        <div class="jz sp">Step5</div>
        <div class="txt2">
          有效期及使用时间：2022年11月25日-11月28日中午12:00<br />
          使用地点：天府书展主展场（成都·新会展1号馆） 使用范围：全场通用<br />
          本券须一次性使用，不找零、不兑现，可叠加，过期作废<br />
          所购商品不退货，可在主会场（成都·新会展1号馆）换货<br />
        </div>
      </div>
    </div>
    <router-link to="/">
        <div class="jz back_cont">
            <div class="jz back_btn">返回展场首页</div>
        </div>
      
    </router-link>
  </div>
</template>
  
  <script>
//这里可以导入其他文件（比如：组件，工具js，第三方插件js，json文件，图片文件等等）

//例如：import 《组件名称》 from '《组件路径》';
import { NavBar, Icon } from "vant";
export default {
  name: "zhuchangText",
  //import引入的组件需要注入到对象中才能使用
  components: {
    [Icon.name]: Icon,
  },
  data() {
    //这里存放数据
    return {};
  },
  //监听属性 类似于data概念
  computed: {},
  //监控data中的数据变化
  watch: {},
  //方法集合
  methods: {
    back() {
      this.$router.back();
    },
  },
  //生命周期 - 创建完成（可以访问当前this实例）
  created() {},
  //生命周期 - 挂载完成（可以访问DOM元素）
  mounted() {},
  beforeCreate() {}, //生命周期 - 创建之前
  beforeMount() {}, //生命周期 - 挂载之前
  beforeUpdate() {}, //生命周期 - 更新之前
  updated() {}, //生命周期 - 更新之后
  beforeDestroy() {}, //生命周期 - 销毁之前
  destroyed() {}, //生命周期 - 销毁完成
  activated() {}, //如果页面有keep-alive缓存功能，这个函数会触发
};
</script>
  <style scoped lang="less">
.zhuchangText {
  position: relative;
  width: 100%;

  background: #ce3444;
  overflow: auto;

  .bg {
    position: absolute;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
  }
}
.head_NavBar .title {
  color: #fff;
}
.text_cont {
  position: relative;
  width: 100%;
  padding: 0 27px;
  text-align: center;
  .text_box {
    position: relative;
    background: #ffffff;
    border-radius: 6px 6px 6px 6px;
    padding: 25px 0;
    margin-bottom: 25px;
    .sp {
      position: absolute;
      top: 0;
      left: 0;
      width: 62px;
      height: 25px;
      background: linear-gradient(227deg, #ff6761 0%, #fe9247 100%);
      border-radius: 6px 0px 6px 0px;
      font-weight: 500;
      color: #ffffff;
      font-size: 12px;
    }
    .title {
      width: 200px;
      margin-bottom: 20px;
    }
    .txt {
      font-size: 12px;

      font-weight: 500;
      color: #d83d49;
    }
    .txt2 {
      position: relative;
      text-align: left;
      margin-top: 20px;
      left: 5%;
      width: 90%;
      font-size: 10px;
      line-height: 2;
      font-family: Source Han Sans CN-Medium, Source Han Sans CN;
      font-weight: 500;
      color: #4e4e4e;
    }
    .fangshi {
      width: 90%;
      margin-top: 20px;
    }
  }
}
.back_cont{
    width: 100%;
}
.back_btn {
  position: relative;
  width: 200px;
  height: 49px;
  background: #ffffff;
  border-radius: 24px 24px 24px 24px;
  font-size: 18px;
  font-weight: 500;
  color: #eb2d3e;
  margin: 40px 0;
}
</style>